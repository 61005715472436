import React, { useState, useEffect } from 'react';

const Countdown: React.FC<{
    startTime: boolean
}> = (props) => {
    const initialTime = 10 * 60;
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const button = document.getElementById('resetOTPResendSaveData')

    useEffect(() => {

        if (timeLeft > 0 && props.startTime) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);


            return () => {
                clearInterval(intervalId);
            };
        }
    }, [timeLeft, props.startTime]);

    useEffect(() => {
        if (!button) return
        if (timeLeft > 0 && props.startTime) {
            button.setAttribute('disabled', 'disabled');
            button.classList.add('disabled:text-gray-400');
        } else {
            button.removeAttribute('disabled');
            button.classList.remove('disabled:text-gray-400');
        }
    }, [timeLeft, props.startTime, button]);


    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60).toString().padStart(2, '0');
        const seconds = (timeLeft % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div>
            {timeLeft > 0 ? <span>{formatTime()}</span> : <span>Time's up!</span>}
        </div>
    );
};

export default Countdown;
